import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "../components/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
	query ProjectTemplateQuery($id: String!) {
		project: sanityProject(id: { eq: $id }) {
			id
			publishedAt
			pdf {
				asset {
					url
				}
			}
			mainImage {
				crop {
					_key
					_type
					top
					bottom
					left
					right
				}
				hotspot {
					_key
					_type
					x
					y
					height
					width
				}
				asset {
					_id
					metadata {
						dimensions {
							aspectRatio
						}
					}
				}
				alt
			}
			otherImages {
				asset {
					_id
					metadata {
						dimensions {
							aspectRatio
						}
					}
				}
			}
			title
			slug {
				current
			}
			_rawBody
			videoURL
			members {
				_key
				person {
					image {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
						}
					}
					name
					slug{
						current
					}
					_rawBio
					_rawResearchSummaryShort
					institution {
						name
						url
					}
					program {
						name
					}
					email
					facebook
					instagram
					linkedIn
					twitter
					vimeo
					keywords {
						value
					}
				}
				roles
			}
		}
	}
`;

const ProjectTemplate = (props) => {
	const { data, errors } = props;
	const project = data && data.project;
	return (
		<Layout>
			{errors && <SEO title="GraphQL Error" />}
			{project && <SEO title={project.title || "Untitled"} />}

			{errors && (
				<Container>
					<GraphQLErrorList errors={errors} />
				</Container>
			)}
			{project && <Project {...project} />}
		</Layout>
	);
};

export default ProjectTemplate;
