import React from "react";
import BlockContent from "../block-content";
import { format } from "date-fns";
import styled from "styled-components";

const MainContent = styled.div`
	font-family: serif;

	@nest & a {
		color: var(--color-accent);

		@media (hover: hover) {
			@nest &:hover {
				color: inherit;
			}
		}
	}
`;

const TextContent = styled.div`
	display: block;

	p {
		font-size: 20px !important;
		line-height: 1.2;
	}
`

function ProjectDescription(props) {
	const { _rawBody, title, members, publishedAt } = props;
	let author = "";

	if (members) {
		author = members[0]?.person.name || '';
	}
	return (
		<MainContent>
			<TextContent>
				{_rawBody && <BlockContent blocks={_rawBody || []} />}
			</TextContent>
		</MainContent>
	);
}

export default ProjectDescription;
