import React from "react";
import Video from "../video";

/**
 * Builds a layout of a project with a single Video and a description
 */
function VideoProject(props) {
	const { title, videoURL } = props;
	return (
		<div>
			<Video videoSrcUrl={videoURL} videoTitle={title} />
			<br />
			<div className="caption">
				<p>
					<em>{props.title}</em>,
					 {new Date(props.publishedAt).getFullYear()}
				</p>
			</div>
		</div>
	);
}

export default VideoProject;
