import styled from "styled-components";
import { setColor, setFont, setRem } from "../../styles/styles";

/* A reusable and customisable button component */
export const StyledButton = styled.button`
	border: 0;
	background-color: ${(props) => (props.bg ? props.bg : "inherit")};
	color: ${(props) => (props.color ? props.color : setColor.mainBlack)};
	text-transform: capitalize;
	font-size: ${(props) => (props.fontSize ? props.fontSize : setRem(10))};
	${setFont.main};
	padding: ${(props) => (props.padding ? props.padding : setRem(0))};
	${(props) =>
		`margin: ${props.t || 0} ${props.r || 0} ${props.b || 0} ${props.l ||
			0}}`};
	cursor: pointer;
	outline: 0;
	&:hover {
		color: ${setColor.mainBlack};
	}
`;
