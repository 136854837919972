import React from "react";
import { Link } from "gatsby"
import slugify from "slugify";
import styled from "styled-components";
import VideoProject from "./project-layouts/video-project";
import ImageProject from "./project-layouts/image-project";
import Book from "./globals/Book";
import PdfProject from "./project-layouts/pdf-project";
import ProjectDescription from "./project-layouts/project-description";

// function slugify(text) {
// 	return text.toString().toLowerCase()
// 	  .replace(/\s+/g, '-')            // Replace spaces with -
// 	  .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
// 	  .replace(/\-\-+/g, '-')         // Replace multiple - with single -
// 	  .replace(/^-+/, '')             // Trim - from start of text
// 	  .replace(/-+$/, '');            // Trim - from end of text
//   }

const Left = styled.div`
	padding: 100px 50px;

	@media (max-width: 1140px) {
		padding: 20px 0px;
	}

	h1, h2, h3 {
		margin: 0;
		font-weight: normal;
		font-size: 24px;
		text-align: center;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	h1 {
		font-size: 76px;
		line-height: 1;
		font-style: italic;
		font-family: serif;
		margin-bottom: 8px;
		@media (max-width: 1140px) {
			font-size: 36px;
		}
	}
	h2 {
		font-family: serif;
	}
	h3 {
		margin-top: 24px;
		margin-bottom: 16px;
		font-family: sans-serif;
	}
`
const Right = styled.div`
	display: flex;
	width: 100%;
	p {
		font-family: serif;
		text-align: center;
	}
`
const InnerProject = styled.div`
	margin: auto 0;
	flex: 1;
`

function Project(props) {
	const { mainImage, members, videoURL, pdf, otherImages } = props;
	// console.log(props.members[0].person.slug.current);



	let projectLayout = null;

	// Determine the project layout from the given data
	if (videoURL) projectLayout = <VideoProject {...props} />;
	else if (pdf) projectLayout = <PdfProject {...props} />;
	else if (mainImage) projectLayout = <ImageProject {...props} />;
	const authors = props.members.map(p => p.person.name).join(' & ');

	let url = "";
	if (props.members[0]){
	  	url = props.members[0].person.slug.current;
	  	// console.log(url);
	} else {
		url = slugify(authors).toLowerCase();
		// console.log("!!!!!!!no person!!!!!");
	}

	return (
		<>
			<Book>
				<Left className="left">
					<h1>{props.title}</h1>
					<h2>{new Date(props.publishedAt).getFullYear()}.</h2>
					<h3><Link to={/profile/+url}>{authors}</Link></h3>
					<ProjectDescription {...props} />
				</Left>
				<Right className="right">
					<InnerProject>
						{projectLayout}
					</InnerProject>
				</Right>
			</Book>
		</>
	);
}

export default Project;
