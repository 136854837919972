import React, { useState, useMemo } from "react";
import { StyledButton } from "../globals/Buttons";
import styled from "styled-components";
import { StandardImg } from "../img";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Container = styled.div`
	text-align: center;

	.popup-content {
		height: 90vh;
		width: 90vw;
	}
`;

const StyledPopup = styled(Popup)`
	&-content {
		height: 90vh;
		width: 90vw;

		iframe {
			height: 100%;
			width: 100%;
		}
	}
`;

/* Creates a PDF modal and uses the main image asset provided to sanity for the project preview.
 * Potential Update: take the first page as the project preview instead
 */
function PdfProject(props) {
	const { url } = props.pdf.asset;
	return (
		<Container>
			<StyledPopup
				// Can't work out how to use custom components as trigger
				trigger={
					<div>
						<StandardImg mainImage={props.mainImage} />
						<div className="caption">
							<p>
								<em>{props.title}</em>, {new Date(props.publishedAt).getFullYear()}
							</p>
						</div>
					</div>
				}
				modal
				position="center center"
			>
				<iframe src={ url } />
			</StyledPopup>
		</Container>
	);
}

export default PdfProject;
