import React from "react";
import ProjectDescription from "./project-description";
import Img from "../img";
import styled from "styled-components";

const ImageContainer = styled.div`
	text-align: center;
`;

/**
 * Builds a layout of a project with a single image and a description
 */
function ImageProject(props) {
	return (
		<div>
			<ImageContainer>
				<Img {...props} />
			</ImageContainer>
			<div className="caption">
				<p>
					<em>{props.title}</em>, {new Date(props.publishedAt).getFullYear()}
				</p>
				<p>1/{props.otherImages.length ? props.otherImages.length+1 : 1}</p>
			</div>
		</div>
	);
}

export default ImageProject;
